import { JackpotLevelType } from '@/types/api/ge-jackpot/jackpot';
import { Box, Link, Text } from '@chakra-ui/react';
import { LightCloseIcon } from '@/theme/Icons';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getRef } from '@/helpers/jackpot';
import { useAppSettings } from '@/context/AppSettings';

type InfoBoxProps = {
  jackpotLevel: JackpotLevelType;
  onClose: () => void;
};
export const JackpotLevelInfo = ({ jackpotLevel, onClose }: InfoBoxProps) => {
  const { t } = useTranslation();
  const ref = getRef(jackpotLevel.ref);

  const {
    features: {
      jackpot: { linkInfo }
    }
  } = useAppSettings();

  return (
    <Box
      width={'100%'}
      border={'1px solid #FFD780'}
      borderRadius={'9.5px'}
      bg={'#141414'}
      padding={'1.25rem'}
      color={'white'}
    >
      <Text
        fontSize={['1rem', '1rem', '1rem', '1.5rem']}
        fontFamily={'avertastd'}
        fontWeight={500}
        lineHeight={['1.375rem', '1.375rem', '1.375rem', '1.875rem']}
        textAlign={'center'}
      >
        {t('jackpot:howToWinJackpotTitle')}
      </Text>
      <Text
        marginTop={'1.25rem'}
        textAlign={'center'}
        fontSize={['0.75rem', '0.75rem', '0.75rem', '1rem']}
        lineHeight={['1rem', '1rem', '1rem', '1.5rem']}
        fontFamily={'avertastd'}
        dangerouslySetInnerHTML={{
          __html: t(`jackpot:howToWinJackpotDescriptionRef${ref}`)
        }}
      ></Text>
      {linkInfo ? (
        <Link
          display={'block'}
          mx={'auto'}
          href={linkInfo}
          marginTop={'1rem'}
          textAlign={'center'}
          color={'#FFD780'}
          fontWeight={500}
          fontSize={'0.875rem'}
          lineHeight={'1.25rem'}
          cursor={'pointer'}
        >
          {t('jackpot:learnMore')}
        </Link>
      ) : null}
      <Box
        bg={'buttonPrimary'}
        display={'block'}
        borderRadius={'100%'}
        width={'1.5rem'}
        height={'1.5rem'}
        textAlign={'center'}
        cursor={'pointer'}
        margin={'1rem auto 0 auto'}
        onClick={onClose}
      >
        <LightCloseIcon />
      </Box>
    </Box>
  );
};
