import {
  JackpotLevelType,
  SVGBronzeLevelMini,
  SVGGoldLevelMini,
  SVGSilverLevelMini
} from '@/components/Jackpots';
import { useNumberAnimation } from '@/hooks/useNumberAnimation';
import { formatCurrency } from '@/hooks/useFormat';
import { Box, BoxProps } from '@chakra-ui/react';

type JackpotLevelMiniProps = BoxProps & {
  jackpotLevel: JackpotLevelType;
  isMobile: boolean;
};

export const JackpotLevelMini = ({
  jackpotLevel,
  isMobile,
  ...props
}: JackpotLevelMiniProps) => {
  // Animate amount
  const amount = useNumberAnimation({
    value: jackpotLevel?.amount,
    animationValueDuration: 10000,
    animationValueStepDuration: 100
  });

  const amountFormatted = formatCurrency({
    amount: amount,
    currency: 'EUR'
  });

  const renderSVG = (jackpotLevel: JackpotLevelType, amount: string) => {
    switch (jackpotLevel.ref) {
      case '1':
        return <SVGGoldLevelMini isMobile={isMobile} amount={amount} />;
      case '2':
        return <SVGSilverLevelMini isMobile={isMobile} amount={amount} />;
      default:
      case '3':
        return <SVGBronzeLevelMini isMobile={isMobile} amount={amount} />;
    }
  };

  return <Box {...props}>{renderSVG(jackpotLevel, amountFormatted)}</Box>;
};
