import { JackpotLevelType } from '@/types/api/ge-jackpot/jackpot';
import { formatCurrency } from '@/hooks/useFormat';
import { getHMSFormat } from '@/helpers/time';

export const getRef = (jackpotLevelRef: string) => {
  return ['1', '2', '3'].includes(jackpotLevelRef)
    ? (jackpotLevelRef as '1' | '2' | '3')
    : '3';
};

type GetDropsBeforeI18nKeyAndValue = {
  jackpotLevel: JackpotLevelType;
  timeRemaining: number;
};
export const getDropsBeforeI18nKeyAndValue = ({
  jackpotLevel,
  timeRemaining
}: GetDropsBeforeI18nKeyAndValue) => {
  const ref = getRef(jackpotLevel.ref);
  let value: string;
  let key: string = `jackpot:dropsBeforeRef${ref}`;

  switch (jackpotLevel.type) {
    case 'total_bet':
      value = formatCurrency({
        amount: +jackpotLevel?.maxValue,
        currency: 'EUR',
        maximumFractionDigits: 0
      });

      return {
        key,
        value
      };
    case 'draw_time':
      // Convert seconds to hours, minutes, and seconds
      if (timeRemaining > 0) {
        value = getHMSFormat(timeRemaining);
        return {
          key,
          value
        };
      } else {
        return {
          key: `jackpot:dropsFinishedRef${ref}`,
          value: ''
        };
      }
  }
};
