import {
  Box,
  Grid,
  GridItem,
  GridProps,
  HStack,
  Img,
  Text,
  VStack
} from '@chakra-ui/react';
import { LogoTestAccount } from '@/components/Logo';
import SocialLinks from '@/components/SocialLinks';
import { Link as LinkChakra } from '@chakra-ui/layout';
import { PaymentMethodsIcons } from '@/components/Footer/index';
import { useAppSettings } from '@/context/AppSettings';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import {
  DomainLicense,
  getLicenseByPath,
  LicensesEnabled
} from '@/utils/multiDomains';
import { useRouter } from 'next/router';

const GamingLicenseCopy = () => {
  const gamingLicenseEnabled = LicensesEnabled().filter(
    (license) => license !== 'MAIN'
  );
  return (
    <Text as="span">
      {gamingLicenseEnabled.map((license) => {
        return (
          <Text key={license} as="span" display="block">
            Gaming License {license}+
          </Text>
        );
      })}
    </Text>
  );
};

const gamingLicenseText: {
  [key in keyof DomainLicense]: string | JSX.Element;
} = {
  A: 'Gaming License A+',
  B: 'Gaming License B+',
  F: 'Gaming License F+',
  MAIN: <GamingLicenseCopy />
};

type FooterMenuTilesProps = GridProps & {};
export const FooterMenuTiles: FC<FooterMenuTilesProps> = () => {
  const config = useAppSettings();
  const { t } = useTranslation();
  const router = useRouter();
  let ageLicenseList: string[] = [];
  const license = getLicenseByPath(router.asPath);

  if (license === 'MAIN') {
    ageLicenseList.push(
      ...LicensesEnabled()
        .filter((license) => license !== 'MAIN')
        .map((license) => t(`footer:ageLicense${license}`))
    );
  } else {
    ageLicenseList.push(t(`footer:ageLicense${license.toString()}`));
  }
  // Remove duplicates
  ageLicenseList = [...new Set(ageLicenseList)];

  const partnersItems: Record<string, any> = {
    gamingCommission: {
      href: t('footer:websiteGamingCommission'),
      imageName: 'gamingcommission',
      text: gamingLicenseText[license]
    },
    bago: {
      href: t('footer:websiteBago'),
      imageName: 'bago',
      text: 'Founding member of'
    }
  };

  const partnersLinks: {
    href: string;
    imageName: string;
    text: string | JSX.Element;
  }[] = config?.partnersItems.map((key: string) => partnersItems[key]);

  return (
    <Grid
      gridTemplateColumns={[
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(6, 1fr)'
      ]}
      padding={['2rem 5% 2.8rem 5%', '2rem 5% 2.8rem 5%', '4rem 3rem']}
      gridRowGap="3rem"
      marginX={['0', 'auto !important', 'auto !important']}
      width={['100%', '90%', '90%']}
      maxWidth="1920px"
      gap={6}
    >
      <GridItem flexDirection="column">
        <LogoTestAccount
          width="8.125rem"
          badgeProps={{
            right: '-1rem'
          }}
          zIndex="inherit"
        />
        <SocialLinks />
      </GridItem>

      <GridItem colSpan={2}>
        <VStack gridColumnStart="span 2" align="baseline">
          <Text fontSize="0.8rem" fontWeight="bold">
            100% {t('footer:belgian')}. 100% {t('footer:secure')}
          </Text>
          <Text fontSize="0.6rem" color="customGrey.900">
            {t('footer:paymentsDescription')}
          </Text>
          <HStack flexWrap={'wrap'}>
            <PaymentMethodsIcons />
          </HStack>
        </VStack>
      </GridItem>
      {partnersLinks?.map((partnerLink) => (
        <GridItem key={partnerLink.href}>
          <a
            href={partnerLink.href}
            data-testid={`${partnerLink.imageName}-link`}
            target="_blank"
            rel="noreferrer"
          >
            <VStack align="baseline">
              {partnerLink.text ? (
                <Text
                  textTransform="uppercase"
                  fontSize="0.8rem"
                  fontWeight="bold"
                  color="#fff"
                >
                  {partnerLink.text}
                </Text>
              ) : null}
              <Img
                src={`/partners/${partnerLink.imageName}.webp`}
                alt={`Partenaire ${partnerLink.text}`}
                loading="lazy"
              />
            </VStack>
          </a>
        </GridItem>
      ))}
      <GridItem>
        <VStack alignItems={['flex-start', 'flex-start', 'center']}>
          <HStack>
            <LinkChakra isExternal href={t('footer:websitePlaySafe')}>
              <Img
                src={`/partners/playsafe-small.webp`}
                maxWidth="4rem"
                alt="Play safe"
                loading="lazy"
              />
            </LinkChakra>
            {ageLicenseList.map((ageLicense, index) => {
              return (
                <Box
                  key={index}
                  background="white"
                  borderRadius="50%"
                  display="inline-block"
                  color="black"
                  fontWeight={'700'}
                  fontSize="0.8rem"
                  padding="0.2rem"
                >
                  {ageLicense}
                </Box>
              );
            })}
          </HStack>
          <Box
            fontSize="0.6rem"
            dangerouslySetInnerHTML={{
              __html: t('footer:dependancePrevention')
            }}
          ></Box>
        </VStack>
      </GridItem>
    </Grid>
  );
};
