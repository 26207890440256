import { JackpotLevelRefType } from '@/types/api/ge-jackpot/jackpot';
import { getRef } from '@/helpers/jackpot';

export const getBorderColor = (level: JackpotLevelRefType) => {
  const ref = getRef(level);
  switch (ref) {
    case '1':
      return 'jackpot.gold.border';
    case '2':
      return 'jackpot.silver.border';
    case '3':
      return 'jackpot.bronze.border';
  }
};

export const getBackgroundUrl = (
  level: JackpotLevelRefType,
  isMobile: boolean
) => {
  const ref = getRef(level);

  if (isMobile) {
    switch (ref) {
      case '1':
        return '/jackpots/gold/hit/bkg-mobile.webp';
      case '2':
        return '/jackpots/silver/hit/bkg-mobile.webp';
      case '3':
        return '/jackpots/bronze/hit/bkg-mobile.webp';
    }
  }

  switch (ref) {
    case '1':
      return '/jackpots/gold/hit/bkg-desktop.webp';
    case '2':
      return '/jackpots/silver/hit/bkg-desktop.webp';
    case '3':
      return '/jackpots/bronze/hit/bkg-desktop.webp';
  }
};
