import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { HTTP } from '@/components';
import { JackpotHitType, JackpotType } from '@/components/Jackpots';
import { useAppSettings } from '@/context/AppSettings';

type JackpotsContextType = {
  enabled: boolean;
  jackpots?: JackpotType[];
  setJackpots: (jackpots: JackpotType[]) => void;

  isDisplayingModalHit: boolean;
  setIsDisplayingModalHit: (b: boolean) => void;
  dataModalJackpotHit: JackpotHitType | null;
  setDataModalJackpotHit: (data: JackpotHitType) => void;
};

export const JackpotsContext = createContext<JackpotsContextType>({
  enabled: false,
  jackpots: [],
  setJackpots: (jackpots: JackpotType[]) => {},
  dataModalJackpotHit: {} as JackpotHitType,
  setDataModalJackpotHit: (data: JackpotHitType) => {},
  isDisplayingModalHit: false,
  setIsDisplayingModalHit: (b: boolean) => {}
});

export const useJackpots = () => useContext(JackpotsContext);

export const JackpotsProvider = ({ children }: PropsWithChildren) => {
  const {
    features: {
      jackpot: { enabled }
    }
  } = useAppSettings();
  const [jackpots, setJackpots] = useState<JackpotType[]>();
  const [isDisplayingModalHit, setIsDisplayingModalHit] =
    useState<boolean>(false);
  const [dataModalJackpotHit, setDataModalJackpotHit] =
    useState<JackpotHitType | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const loadJackpots = async () => {
      const response = await HTTP.get(`jackpots`);
      // console.log('Jackpots -> loadJackpots', response?.data?.rows);
      setJackpots(response?.data?.rows);

      // in order to have updated data, we trigger a load at the end of upcoming.timestamp
      const firstJackpot = Array.from(response?.data?.rows || []).shift() as
        | JackpotType
        | undefined;
      if (firstJackpot) {
        const firstUpcoming = firstJackpot.upcoming.shift();
        if (firstUpcoming?.timestamp) {
          const remaining = Math.max(
            0,
            new Date(firstUpcoming.timestamp).getTime() - Date.now()
          );
          if (remaining) {
            // extended waiting time
            timer = setTimeout(() => void loadJackpots(), remaining + 3 * 1000);
          }
        }
      }
    };
    void loadJackpots();

    return () => timer && clearTimeout(timer);
  }, []);

  return (
    <JackpotsContext.Provider
      value={{
        enabled,
        jackpots,
        setJackpots,
        isDisplayingModalHit,
        setIsDisplayingModalHit,
        dataModalJackpotHit,
        setDataModalJackpotHit
      }}
    >
      {children}
    </JackpotsContext.Provider>
  );
};
